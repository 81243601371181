.App {
  text-align: center;
  
}

nav{
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 8vh;
  background: black;
  color: white;
}





.nav-links{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;

}





html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}