
.ManagePersonalInfoGroup {

    display: grid;
    margin-top : 30px;
    margin-left : 330px;
    
}


  
@media (max-width: 1060px) {
    .ManagePersonalInfoGroup {
       
        margin-left : 130px;
      
    }
}
  
@media(max-width: 720px) {
   .ManagePersonalInfoGroup {
       
       margin-left : 20px;
    
    }
}



.ManagePersonalInfoGroup h2{
   
    font-weight: 600;
    font-size: 20px;
}


.ManagePersonalInfoGroup h3{
    margin-top: 40px;
    font-size: 15px;
    font-weight: 450;
  
}



.ManagePersonalInfoGroup h5{
    
    font-size: 15px;
    font-weight: 250;
   
}


 



.ManagePersonalInfoGroup img {
    
    width: 330px;
    height: 330px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    

   
}



.editbutton {
    margin-top: 80px;
    margin-bottom: 80px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white;
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    
    

}


.editbutton:focus {
    outline:0;
}



.editbutton:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    color: black;
}
  