
.CreateClassGroup {

    display: grid;
    margin-top : 30px;
    margin-bottom : 80px;
    margin-left : 330px;
    
}

@media (max-width: 1060px) {
    .CreateClassGroup {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .CreateClassGroup {
       
       margin-left : 20px;
    
    }
}




.CreateClassGroup h2 {
    font-weight: 600;
    font-size: 20px;
}

.CreateClassGroup label {
    margin-top: 40px;
    font-size: 15px;
    font-weight: 450;

}


.CreateClassGroup input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    border-width: thin;
    font-size: 15px;
    font-weight: 250;

}




textarea {
    width: 330px;
    height: 340px;

    padding: 10px 10px;
    cursor: pointer;
    overflow: hidden;
    
}
   


.CreateClassGroup input[type=submit] {
    /* save button */
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;
    

}


.CreateClassGroup input[type=submit]:focus {
    outline:0;
}



.CreateClassGroup input[type=submit]:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}