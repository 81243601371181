
.Login {

    display: grid;

    width: 340px;
    

}





.Login h3{
   
    font-weight: 700;
    font-size: 11px;
}




.Login input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 17px;
    font-weight: 400;
    margin-top: 30px;
    border-width: thin;
    
    
}

@media(max-width: 580px) {
    .Login input{
      width: 290px;
        
      }
}


.Login input[type=submit] {
    /* save button */
    margin-top: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
  
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    

}

@media(max-width: 580px) {
    .Login input[type=submit] {
      width: 290px;
        
      }
}
  





.Login input[type=submit]:focus {
    outline:0;
}



.Login input[type=submit]:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}

.example {
    text-align: center;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
  }