
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');

.InstructorComponent {
   
    width: 300px;
    height: 300px;
    position: relative;
    overflow: hidden;
   
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    display: grid;
    grid-template-rows: 1fr 1fr;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
}

.InstructorComponent:hover {
    
   
    box-shadow: 0 30px 60px rgba(0,0,0, 0.5);
}


.InstructorComponent img {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 0;
    z-index: -1;
    
}





.InstructorComponent a{
    
    width:300px;
    height:300px;
}



.InstructorComponent h3 {
    
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0 0 20px;
    font-family: 'Cormorant', serif;
    
}

.InstructorComponent a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}
