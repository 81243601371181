.button {
    font-size: 18px;
    font-weight: 600;
    width: 330px;
    height: 50px;
    background-color: black; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    margin-top: 80px;
    

}


.button:focus {
    outline:0;
}



.button:hover {
    text-decoration: none;

    background-color: rgba(0, 0, 0, 0.8); /* Green */
    color: white;
}



.ConnectToStripe {
   

    margin: 90px 20px 90px 190px ;

    
   
}

@media (max-width: 1060px) {
    .ConnectToStripe {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .ConnectToStripe {
       
       margin-left : 20px;
    
    }
}