@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400;500;600&display=swap');

.NavGroup {
  height: 80px;
  
}


.LinksGroup {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 30px 25px 10px 50px;
}


.LinksGroup a {
  text-decoration: none;
  color: white;
  font-family: 'Noto Serif JP', serif;

}

.LinksGroup a:hover {
  text-decoration: none;
  color: rgba(143, 137, 137, 0.9);
}



.LinksGroup li {
  font-size: 20px;
  font-weight: 550;
}
