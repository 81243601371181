
.ContactPage {

    display: grid;
    margin-top : 60px;
    margin-bottom : 500px;
    margin-left : 330px;
    grid-gap: 30px;
    width: 500px;
  
  
    
  }
  

  .ContactPage h2{
    font-size: 18px;
    font-weight: 600;
  }
  
  
  @media (max-width: 1060px) {
    .ContactPage {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 130px;
      grid-gap: 30px;
      width: 500px;
    }
  }
  
  @media(max-width: 720px) {
   .ContactPage {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 20px;
      grid-gap: 30px;
      width: 500px;
      
    }
  }
  
  
  
  @media(max-width: 480px) {
    .ContactPage {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 15px;
      margin-right : 20px;
      grid-gap: 30px;
      width: 260px;
      
    }
  }
  