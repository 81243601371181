
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
.ClassComponent {
    

    width: 480px;
    height: 70px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;

  
}


.xxx {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    padding: 30px;
    /* padding : 30px 20px; */
   
}

@media(max-width: 480px) {
    .xxx {
        padding : 30px 15px;
        
     }
  
}
   

@media(max-width: 720px) {
    .ClassComponent {
        width: 400px;
       
     }
  
}


@media(max-width: 480px) {
    .ClassComponent {
        width: 330px;
        
     }
  
}
   

.ClassComponent h5 {
    color: black;
    font-size: 15px;
    font-weight: 400;

}

@media(max-width: 480px) {
    .ClassComponent h5 {
        font-size: 12px;
        
     }
  
}



.ClassComponent:hover {
    
    box-shadow: 0 15px 30px rgba(90, 88, 88, 0.4);
}




.ClassComponent a:hover, a:visited, a:link, a:active{
    text-decoration: none;
}