

@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');

.ManageSessionComponent {

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 570px;
    height: 90px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    padding: 20px;
}

@media (max-width: 1060px) {
    .ManageSessionComponent {
       
        width: 470px;
      
    }
}
  
@media(max-width: 720px) {
   .ManageSessionComponent {
       width: 330px;
    
    }
}


.ManageSessionComponent h4 {
    font-size: 17px;
    color: black;
    font-family: 'Cormorant', serif;
   
    
}

@media(max-width: 720px) {


    .ManageSessionComponent h4 {
        font-size: 13px;
        
      
        
    }
 }
 


.ManageSessionComponent button {
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 130px;
    height: 40px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;
}


@media(max-width: 720px) {
    .ManageSessionComponent button {
        width: 100px;
     
     }
 }
 

.ManageSessionComponent button:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}

