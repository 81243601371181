.TransactionRecord {
    display: grid;

   
    grid-gap: 2em;
    margin: 90px 20px 90px 190px ;

    
   
}

@media (max-width: 1060px) {
    .TransactionRecord {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .TransactionRecord {
       
       margin-left : 20px;
    
    }
}