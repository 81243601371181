





.FAQ {

    display: grid;
    margin-top : 60px;
    margin-bottom : 300px;
    margin-left : 330px;
    grid-gap: 30px;
    width: 500px;
  
  
    
  }
  
  
  @media (max-width: 1060px) {
    .FAQ {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 130px;
      grid-gap: 30px;
      width: 500px;
    }
  }
  
  @media(max-width: 720px) {
   .FAQ {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 20px;
      grid-gap: 30px;
      width: 500px;
      
    }
  }
  
  
  
  @media(max-width: 480px) {
    .FAQ {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 15px;
      margin-right : 20px;
      grid-gap: 30px;
      width: 260px;
      
    }
  }