@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');

.StudentInfoBox {

    width: 580px;
    height: 80px;

    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    justify-content: stretch;  
    margin-top: 20px;


    
}

@media (max-width: 1060px) {
    .StudentInfoBox {
       
        width : 550px;
      
    }
}
  
@media(max-width: 720px) {
   .StudentInfoBox {
       
       width : 320px;
       height: 45px;
    
    }
}



.cccc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    
   
   
}


@media(max-width: 720px) {
    .cccc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        
       
       
    }
    
 }



.StudentInfoBox h4 {
    
    color: black;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Cormorant', serif;

}

@media(max-width: 720px) {
    .StudentInfoBox h4 {
        
        font-size: 11px;
     
     }
 }

 





