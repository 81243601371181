


.StudentSchedule {
   

    margin: 30px 20px 400px 190px ;
    width: 300px;
   

    
   
}


.StudentSchedule h5{
   

    font-size: 15px;
    font-weight: 350; 

    
   
}

@media (max-width: 1060px) {
    .StudentSchedule {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .StudentSchedule {
       
       margin-left : 20px;
    
    }
}


.StudentSchedule h2 {
    
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;

   
}

.BookingItems {
    margin-top: 20px;
    
    display: grid;
    grid-gap: 20px;

   
}




.StudentSchedule input{
    /* this is for the text field  */
    width: 300px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 250;
    border-width: thin;
    
    
}