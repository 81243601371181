@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');

.SessionComponent {

    display: flex;
    justify-content: space-between;
    align-items: center;
    
   
    width: 500px;
    height: 80px;
    
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    padding-left: 50px;
    padding-right: 30px;
}


@media(max-width: 720px) {
    .SessionComponent {
        width: 420px;
        padding: 20px;
        
       
     }  
}


@media(max-width: 480px) {
    .SessionComponent {
        width: 330px;
        padding: 15px
       
     }
}
   






.SessionComponent h4 {
    
    color: black;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Playfair Display', serif; 

   
}


@media(max-width: 720px) {
    .SessionComponent h4 {
        font-size: 13px;
       
     }  
}


@media(max-width: 480px) {
    .SessionComponent h4 {
        font-size: 11px;
       
     }
}
   


.SessionComponent button {
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 12px;
    font-weight: 300;
    width: 110px;
    height: 35px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-width: thin;

}


.SessionComponent button:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
  
}