
.NewSignUp {

    display: grid;

    width: 340px;
    margin: 60px;
    padding-bottom: 250px;

    
   
    
}


@media(max-width: 580px) {
    .NewSignUp {
      margin: 20px;
        
      }
}





.NewSignUp h3{
   
    font-weight: 700;
    font-size: 11px;
}

.NewSignUp input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 17px;
    font-weight: 400;
    margin-top: 30px;
    border-width: thin;
    
    
}

@media(max-width: 580px) {
    .NewSignUp input{
      width: 290px;
        
      }
}




.errorMessage {
    color: black;
    font-size: 0.75em;
    display: relative;
}




.NewSignUp button {
    /* upload button */
    margin-top: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
  
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;

    

}


@media(max-width: 580px) {
    .NewSignUp button {
      width: 290px;
        
      }
}
  


.NewSignUp button:focus {
    outline:0;
}



.NewSignUp button:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}



