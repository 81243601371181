@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');

.ClassComponentUnderInstructorSchedule {

    width: 580px;
    height: 80px;

    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    justify-content: stretch;  


    
}

@media (max-width: 1060px) {
    .ClassComponentUnderInstructorSchedule {
       
        width : 550px;
      
    }
}
  
@media(max-width: 720px) {
   .ClassComponentUnderInstructorSchedule {
       
       width : 320px;
       height: 45px;
    
    }
}



.ssssss {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    
   
   
}


@media(max-width: 720px) {
    .ssssss {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        
       
       
    }
    
 }



.ClassComponentUnderInstructorSchedule h4 {
    
    color: black;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Cormorant', serif;

}

@media(max-width: 720px) {
    .ClassComponentUnderInstructorSchedule h4 {
        
        font-size: 11px;
     
     }
 }

 


.ClassComponentUnderInstructorSchedule h5 {
    color: black;
    font-size: 13px;
    font-weight: 400;
    
}

.ClassComponentUnderInstructorSchedule:hover {
    
    box-shadow: 0 15px 30px rgba(90, 88, 88, 0.4);
}




.ClassComponentUnderInstructorSchedule a:hover, a:visited, a:link, a:active{
    text-decoration: none;
}





