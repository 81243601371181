.ManageClassComponent {

    width: 400px;
    height: 80px;

    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    
}

@media(max-width: 580px) {
    .ManageClassComponent {
        
        width: 300px;
        height: 70px;
        
      }
  
  }


.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
   
}




.ManageClassComponent h4 {
    
    color: black;
    font-size: 15px;
    font-weight: 300;
    
}


.ManageClassComponent:hover {
    
    box-shadow: 0 15px 30px rgba(90, 88, 88, 0.4);
}




.ManageClassComponent a:hover, a:visited, a:link, a:active{
    text-decoration: none;
}