
.ManageClassDetailGroup {

    display: grid;
    margin-top : 30px;
    margin-left : 330px;
    /* margin-right : 20px; */
    margin-bottom : 130px;
    /* width: 250px; */

}





@media (max-width: 1060px) {
    .ManageClassDetailGroup {
       
        margin-left : 130px;
        width: 300px;
    }
}
  
@media(max-width: 720px) {
   .ManageClassDetailGroup {
       
       margin-left : 10px;

    }  
}

  

.ManageClassDetailGroup h2 {
    font-weight: 600;
    font-size: 20px;
}


.ManageClassDetailGroup h3 {
    margin-top: 40px;
    font-size: 15px;
    font-weight: 450;
}


@media(max-width: 720px) {

    .ManageClassDetailGroup h3 {
 
      
        width: 300px;   
    } 
 }
 



.ManageClassDetailGroup h4 {
 
    line-height: 1.5;
    font-size: 15px;
    font-weight: 250;
}




.ManageClassDetailGroup h5 {
 
    line-height: 1.5;
    font-size: 15px;
    font-weight: 250;
    width: 500px;   
}


@media(max-width: 720px) {

    .ManageClassDetailGroup h5 {
 
      
        width: 330px;   
    } 
 }
 





.ManageClassDetailGroup label {
    margin-top: 40px;
    font-size: 15px;
    font-weight: 450;
}


.ManageClassDetailGroup input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 250;
    border-width: thin;
    
}

button.commonButton {
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;
}


button.commonButton:focus {
    outline:0;
}


button.commonButton:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}


.TimePicker {
    width: 330px;

}


.SessionGroup {
    margin-top: 30px;
    margin-bottom: 30px;
    display: grid;
    grid-gap: 30px;
}
