.ManageClassGroup {

 
  margin-top : 30px;
  margin-left : 90px;
  margin-bottom : 300px;
 
  
}







@media (max-width: 1060px) {
  .ManageClassGroup {
     
    margin-left : 90px;
    
  }
}


@media(max-width: 720px) {
 .ManageClassGroup {
     
     margin-left : 20px;
  
  }
}







.ManageClassGroup h2{
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 20px;
    
  
}


.ClassGroup {

  display: grid;
  grid-gap: 30px;
  
}
