

.BecomeAnInstructor {

    display: grid;
    margin: 30px 20px 600px 190px ;

    width: 650px;
    
}




@media (max-width: 1060px) {
    .BecomeAnInstructor {
       
        margin-left : 30px;
        width: 400px;
      
    }
}
  
  @media(max-width: 720px) {
   .BecomeAnInstructor {
       
       margin-left : 20px;
       width: 350px;
    
    }
}


.BecomeAnInstructor h6{
 font-weight: 350;
    
}










