.PurchaseHistory {
    display: grid;
    grid-gap: 2em;
    margin-top : 90px;
    margin-right : 590px;
    margin-left : 190px;
    margin-bottom : 90px;
}




  
@media (max-width: 1060px) {
    .PurchaseHistory {
        margin-left : 130px;
    }
}
  
@media(max-width: 720px) {
   .PurchaseHistory {
       margin-left : 20px;
    
    }
}