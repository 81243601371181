



  .ClassDetail h3{
    margin: 50px 40px 40px 0;
   
    font-weight: 600;
    font-size: 20px;
    
  
  }


.ClassDetail h5{
    margin: 20px 40px 5px 0;
    width: 560px;
   
    font-size: 15px;
    font-weight: 450;
  
}

@media(max-width: 580px) {
  .ClassDetail h5 {
    width: 300px;
      
    }
}




.ClassDetail h6{
    margin: 0 40px 40px 0;
    width: 560px;
    font-size: 15px;
    font-weight: 250;
    line-height: 1.5;
    
 
  
}



.ClassDetail {
  margin-left : 260px;
  margin-bottom : 190px;
  width: 700px;
}


@media (max-width: 1060px) {
  .ClassDetail {
    margin-left : 160px;
  }

  .ClassDetail h6{
    width: 500px;
  }
}


@media(max-width: 720px) {
 .ClassDetail {
  margin-left : 30px;
    
  }
  .ClassDetail h6{
    width: 430px;
  }
}

@media(max-width: 480px) {
  .ClassDetail {
    margin-left : 15px;
      
    }
    .ClassDetail h6{
      width: 300px;
    }

}
