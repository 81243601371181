

.PersonalInfoGroup {

    display: grid;
    margin-top : 30px;
    margin-left : 330px;
    
}


  
@media (max-width: 1060px) {
    .PersonalInfoGroup {
       
        margin-left : 130px;
      
    }
}
  
@media(max-width: 720px) {
   .PersonalInfoGroup {
       
       margin-left : 20px;
    
    }
}


.avatar-uploader > .ant-upload {
    margin-top : 60px;
    width: 330px;
    height: 330px;
    margin-bottom : 20px;
  }



.PersonalInfoGroup h2 {
    
    font-weight: 600;
    font-size: 20px;
}

.PersonalInfoGroup label {


    margin-top: 40px;
    font-size: 15px;
    font-weight: 450;

}


.PersonalInfoGroup input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    border-width: thin;
    font-size: 15px;
    font-weight: 250;
    
    
}



.PersonalInfoGroup img {
    margin-top: 40px;
    
    width: 330px;
    height: 330px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    
}




.PersonalInfoGroup button {
    /* upload button */
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;
    
    

}


.PersonalInfoGroup button:focus {
    outline:0;
}



.PersonalInfoGroup button:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}



.PersonalInfoGroup input[type=submit] {
    /* save button */
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;
    

}


.PersonalInfoGroup input[type=submit]:focus {
    outline:0;
}



.PersonalInfoGroup input[type=submit]:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}




