@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');





  .ClassInfoGroup h3{
    margin: 50px 40px 40px 0;
    
    font-weight: 600;
    font-size: 25px;

    font-family: 'Cormorant', serif;
  }


  .ClassInfoGroup h5{
    margin: 20px 40px 5px 0;
    width: 560px;
    font-size: 15px;
    font-weight: 450;
  
}



  .ClassInfoGroup h6{
    margin: 0 40px 40px 0;
    width: 560px;
    font-size: 15px;
    font-weight: 250;
    line-height: 1.5;
    
  
}


.sessionGroup h3{
  margin: 50px 40px 0 0;
  font-size: 15px;
  font-weight: 450;
}

.sessionGroup h6{
  margin: 10px 40px 20px 0;
  width: 560px;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 250;
}




.ClassInfoGroup {
  margin-left : 260px;
}


@media (max-width: 1060px) {
  .ClassInfoGroup {
    margin-left : 160px;
  }

  .ClassInfoGroup h6{
    width: 500px;
  }

  .sessionGroup h6{
    width: 500px;
  }
}


@media(max-width: 720px) {
 .ClassInfoGroup {
  margin-left : 30px;
    
  }
  .ClassInfoGroup h6{
    width: 430px;
  }

  .sessionGroup h6{
    width: 430px;
  }
}

@media(max-width: 480px) {
  .ClassInfoGroup {
    margin-left : 15px;
      
    }
    .ClassInfoGroup h6{
      width: 330px;
    }

    .sessionGroup h6{
      width: 330px;
    }

}






.sessionGroup {
  margin-left : 260px;
  width: 300px;
  margin-bottom: 400px;
}

@media (max-width: 1060px) {
  .sessionGroup {
    margin-left : 160px;
  }


}


@media(max-width: 720px) {
  .sessionGroup {
   margin-left : 30px;
     
   }
 }


@media(max-width: 480px) {
  .sessionGroup {
    margin-left : 15px;
     
  }

}



.sessions {
    margin: 50px 40px 150px 0;
    
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    
}




