@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');

.InstructorInfoGroup {

  display: grid;
  margin-top : 60px;
  margin-bottom : 300px;
  margin-left : 330px;
  grid-gap: 30px;
  width: 500px;


  
}


@media (max-width: 1060px) {
  .InstructorInfoGroup {
    display: grid;
    margin-top : 60px;
    margin-left : 130px;
    grid-gap: 30px;
    width: 500px;

  }
}

@media(max-width: 720px) {
 .InstructorInfoGroup {
    display: grid;
    margin-top : 60px;
    margin-left : 20px;
    grid-gap: 30px;
    width: 500px;
    
  }
}



@media(max-width: 480px) {
  .InstructorInfoGroup {
    display: grid;
    margin-top : 60px;
    margin-left : 15px;
    grid-gap: 30px;
    width: 260px;
    
  }
}



.InstructorInfoGroup img{

  width: 480px;
  height: 480px;


}


@media(max-width: 720px) {
  .InstructorInfoGroup img{
   
    width: 400px;
    height: 400px;
  
  
  }
 
 
 }
 
 
 
 @media(max-width: 480px) {
  .InstructorInfoGroup img{
   
    width: 330px;
    height: 330px;
  
  
  }
 
 }

 

.InstructorInfoGroup h2 {
  
  font-size: 23px;
  font-family: 'Cormorant', serif;
  font-weight: 600;

  

}








 








