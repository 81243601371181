




.AddInstructor {
    display: grid;
   

    margin: 30px 20px 400px 190px ;

    
   
}

@media (max-width: 1060px) {
    .AddInstructor {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .AddInstructor {
       
       margin-left : 20px;
    
    }
}





.AddInstructor h2 {
    
    font-weight: 700;
    margin-bottom: 60px;
}




.AddInstructor input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 20px;
    
    
}

.AddInstructor button {
    /* upload button */
    font-size: 18px;
    font-weight: 600;
    width: 330px;
    height: 50px;
    background-color: black; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    

}


.AddInstructor button:focus {
    outline:0;
}



.AddInstructor button:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.8); /* Green */
    color: white;
}
