
.LinkGroup  nav{
   
    height: 200px;
    padding-top: 60px;
 

}


.CompanyGroup li {
    font-size: 15px;
    font-weight: 550;
  }