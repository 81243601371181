
@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');

.ClassComponentUnderStudentSchedule {

    width: 640px;
    height: 80px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;

    justify-content: stretch;  
 
    
}

.zzzz {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    
   
   
}


@media(max-width: 720px) {
    .zzzz {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        
       
       
    }
    
 }
 


@media (max-width: 1060px) {
    .ClassComponentUnderStudentSchedule {
       
        width : 600px;
      
    }
}
  
  @media(max-width: 720px) {
   .ClassComponentUnderStudentSchedule {
       
       width : 300px;
       height: 45px;
    
    }
}





.ClassComponentUnderStudentSchedule h4 {
    
    color: black;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Cormorant', serif;
    
}

@media (max-width: 1060px) {
    .ClassComponentUnderStudentSchedule h4 {
       
        font-size: 12px;
      
    }
}
  
  @media(max-width: 720px) {
   .ClassComponentUnderStudentSchedule h4 {
       
    font-size: 10px;
    
    }
}




.ClassComponentUnderStudentSchedule:hover {
    
    box-shadow: 0 15px 30px rgba(90, 88, 88, 0.4);
}




.ClassComponentUnderStudentSchedule a:hover, a:visited, a:link, a:active{
    text-decoration: none;
}