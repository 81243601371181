.Dashboard {
    display: grid;
    margin-top: 90px;
    margin-left: 70px;
    grid-gap: 2em;
    margin-bottom: 400px;

    
   
}


@media (max-width: 1060px) {
    .Dashboard {
        margin-top: 40px;
        margin-left: 20px;
    }
}
  
@media (max-width: 420px) {
    .Dashboard {
        margin-top: 40px;
        margin-left: 20px;
    }
}




.Dashboard h2 {
    
    color: black;
    font-size: 20px;
    font-weight: 600;
}






.DashboardGroup {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
    grid-row-gap: 3em;

    
   
}


  
@media (max-width: 960px) {
    .DashboardGroup {
      grid-template-columns: repeat(2, 1fr);
    }
}
  
@media (max-width: 820px) {
    .DashboardGroup {
      grid-template-columns: repeat(1, 1fr);
    }
}





.DashboardItem {
    display: grid;

    padding: 20px 20px;
    width: 330px;
    height: 120px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
   
    
    cursor: pointer;
    
}


@media (max-width: 1060px) {
    .DashboardItem {
        width: 300px;
        height: 110px;
    }
}
  




.DashboardItem h2 {
    
    color: black;
    font-size: 15px;
    font-weight: 420;
}



.DashboardItem h3 {
    
    color: gray;
    font-size: 14px;
    font-weight: 400;
}






.DashboardGroup a:hover {
    text-decoration: none;

  }