.TransactionRecordItemComponent {
    display: grid;

    padding: 20px;

   
    width: 700px;
    height:150px;
    
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
   
    
    cursor: pointer;
    
}


.TransactionRecordItemComponent h4 {
    color: black;
    font-size: 17px;
    font-weight: 400;
}



.TransactionRecordItemComponent h3 {
    color: black;
    font-size: 17px;
    font-weight: 600;
}