@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  
}

nav{
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 8vh;
  background: black;
  color: white;
}





.nav-links{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;

}





html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}
.NavGroup {
  height: 80px;
  
}


.LinksGroup {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 30px 25px 10px 50px;
}


.LinksGroup a {
  text-decoration: none;
  color: white;
  font-family: 'Noto Serif JP', serif;

}

.LinksGroup a:hover {
  text-decoration: none;
  color: rgba(143, 137, 137, 0.9);
}



.LinksGroup li {
  font-size: 20px;
  font-weight: 550;
}

button {
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 150px;
    height: 30px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    
    border-width: thin;
}


button:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
    
}















.LinkGroup  nav{
   
    height: 200px;
    padding-top: 60px;
 

}


.CompanyGroup li {
    font-size: 15px;
    font-weight: 550;
  }

  .AboutUs {

    display: grid;
    margin-top : 60px;
    margin-bottom : 500px;
    margin-left : 330px;
    grid-gap: 30px;
    width: 500px;
    
  
  
    
  }



  .AboutUs h2{

    font-size: 18px;
    font-weight: 600;
  
    
  }
  
  


  @media (max-width: 1060px) {
    .AboutUs {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 130px;
      grid-gap: 30px;
      width: 500px;
    }
  }
  
  @media(max-width: 720px) {
   .AboutUs {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 20px;
      grid-gap: 30px;
      width: 450px;
      
    }
  }
  
  
  
  @media(max-width: 480px) {
    .AboutUs {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 15px;
      margin-right : 20px;
      grid-gap: 30px;
      width: 260px;
      
    }
  }


.InstructorComponent {
   
    width: 300px;
    height: 300px;
    position: relative;
    overflow: hidden;
   
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    display: grid;
    grid-template-rows: 1fr 1fr;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
}

.InstructorComponent:hover {
    
   
    box-shadow: 0 30px 60px rgba(0,0,0, 0.5);
}


.InstructorComponent img {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 0;
    z-index: -1;
    
}





.InstructorComponent a{
    
    width:300px;
    height:300px;
}



.InstructorComponent h3 {
    
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0 0 20px;
    font-family: 'Cormorant', serif;
    
}

.InstructorComponent a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}

.SearchGroup  {
 
  margin-top: 30px;


}



.SearchGroup img{

  /* margin: auto 180px; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 150px;
  


}


@media (max-width: 1060px) {
  .SearchGroup img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    height: 400px;
    margin-bottom: 80px;
  }
}

@media (max-width: 720px) {
  .SearchGroup img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 300px;
    margin-bottom: 1px;
  }

}



.SearchGroup h2 {
  font-size: 35px;
  font-weight: 600;
  font-family: 'Cormorant', serif;
  text-align: center;

}



.InstructorGroup h2 {
  margin: 70px 20px;
  font-size: 35px;
  text-align: center;
  font-weight: 600;
  font-family: 'Cormorant', serif;

}


.Instructors {
    margin: 50px 40px 300px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    justify-items: center;
}


  
@media (max-width: 1060px) {
    .Instructors {
      grid-template-columns: repeat(2, 1fr);
    }
}
  
@media (max-width: 720px) {
    .Instructors {
      grid-template-columns: repeat(1, 1fr);
    }

}



.SessionComponent {

    display: flex;
    justify-content: space-between;
    align-items: center;
    
   
    width: 500px;
    height: 80px;
    
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    padding-left: 50px;
    padding-right: 30px;
}


@media(max-width: 720px) {
    .SessionComponent {
        width: 420px;
        padding: 20px;
        
       
     }  
}


@media(max-width: 480px) {
    .SessionComponent {
        width: 330px;
        padding: 15px
       
     }
}
   






.SessionComponent h4 {
    
    color: black;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Playfair Display', serif; 

   
}


@media(max-width: 720px) {
    .SessionComponent h4 {
        font-size: 13px;
       
     }  
}


@media(max-width: 480px) {
    .SessionComponent h4 {
        font-size: 11px;
       
     }
}
   


.SessionComponent button {
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 12px;
    font-weight: 300;
    width: 110px;
    height: 35px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-width: thin;

}


.SessionComponent button:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
  
}
.ClassInfoGroup h3{
    margin: 50px 40px 40px 0;
    
    font-weight: 600;
    font-size: 25px;

    font-family: 'Cormorant', serif;
  }


  .ClassInfoGroup h5{
    margin: 20px 40px 5px 0;
    width: 560px;
    font-size: 15px;
    font-weight: 450;
  
}



  .ClassInfoGroup h6{
    margin: 0 40px 40px 0;
    width: 560px;
    font-size: 15px;
    font-weight: 250;
    line-height: 1.5;
    
  
}


.sessionGroup h3{
  margin: 50px 40px 0 0;
  font-size: 15px;
  font-weight: 450;
}

.sessionGroup h6{
  margin: 10px 40px 20px 0;
  width: 560px;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 250;
}




.ClassInfoGroup {
  margin-left : 260px;
}


@media (max-width: 1060px) {
  .ClassInfoGroup {
    margin-left : 160px;
  }

  .ClassInfoGroup h6{
    width: 500px;
  }

  .sessionGroup h6{
    width: 500px;
  }
}


@media(max-width: 720px) {
 .ClassInfoGroup {
  margin-left : 30px;
    
  }
  .ClassInfoGroup h6{
    width: 430px;
  }

  .sessionGroup h6{
    width: 430px;
  }
}

@media(max-width: 480px) {
  .ClassInfoGroup {
    margin-left : 15px;
      
    }
    .ClassInfoGroup h6{
      width: 330px;
    }

    .sessionGroup h6{
      width: 330px;
    }

}






.sessionGroup {
  margin-left : 260px;
  width: 300px;
  margin-bottom: 400px;
}

@media (max-width: 1060px) {
  .sessionGroup {
    margin-left : 160px;
  }


}


@media(max-width: 720px) {
  .sessionGroup {
   margin-left : 30px;
     
   }
 }


@media(max-width: 480px) {
  .sessionGroup {
    margin-left : 15px;
     
  }

}



.sessions {
    margin: 50px 40px 150px 0;
    
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    
}






.ClassComponent {
    

    width: 480px;
    height: 70px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;

  
}


.xxx {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    padding: 30px;
    /* padding : 30px 20px; */
   
}

@media(max-width: 480px) {
    .xxx {
        padding : 30px 15px;
        
     }
  
}
   

@media(max-width: 720px) {
    .ClassComponent {
        width: 400px;
       
     }
  
}


@media(max-width: 480px) {
    .ClassComponent {
        width: 330px;
        
     }
  
}
   

.ClassComponent h5 {
    color: black;
    font-size: 15px;
    font-weight: 400;

}

@media(max-width: 480px) {
    .ClassComponent h5 {
        font-size: 12px;
        
     }
  
}



.ClassComponent:hover {
    
    box-shadow: 0 15px 30px rgba(90, 88, 88, 0.4);
}




.ClassComponent a:hover, a:visited, a:link, a:active{
    text-decoration: none;
}
.InstructorInfoGroup {

  display: grid;
  margin-top : 60px;
  margin-bottom : 300px;
  margin-left : 330px;
  grid-gap: 30px;
  width: 500px;


  
}


@media (max-width: 1060px) {
  .InstructorInfoGroup {
    display: grid;
    margin-top : 60px;
    margin-left : 130px;
    grid-gap: 30px;
    width: 500px;

  }
}

@media(max-width: 720px) {
 .InstructorInfoGroup {
    display: grid;
    margin-top : 60px;
    margin-left : 20px;
    grid-gap: 30px;
    width: 500px;
    
  }
}



@media(max-width: 480px) {
  .InstructorInfoGroup {
    display: grid;
    margin-top : 60px;
    margin-left : 15px;
    grid-gap: 30px;
    width: 260px;
    
  }
}



.InstructorInfoGroup img{

  width: 480px;
  height: 480px;


}


@media(max-width: 720px) {
  .InstructorInfoGroup img{
   
    width: 400px;
    height: 400px;
  
  
  }
 
 
 }
 
 
 
 @media(max-width: 480px) {
  .InstructorInfoGroup img{
   
    width: 330px;
    height: 330px;
  
  
  }
 
 }

 

.InstructorInfoGroup h2 {
  
  font-size: 23px;
  font-family: 'Cormorant', serif;
  font-weight: 600;

  

}








 










.ClassComponentUnderStudentSchedule {

    width: 640px;
    height: 80px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;

    justify-content: stretch;  
 
    
}

.zzzz {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    
   
   
}


@media(max-width: 720px) {
    .zzzz {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        
       
       
    }
    
 }
 


@media (max-width: 1060px) {
    .ClassComponentUnderStudentSchedule {
       
        width : 600px;
      
    }
}
  
  @media(max-width: 720px) {
   .ClassComponentUnderStudentSchedule {
       
       width : 300px;
       height: 45px;
    
    }
}





.ClassComponentUnderStudentSchedule h4 {
    
    color: black;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Cormorant', serif;
    
}

@media (max-width: 1060px) {
    .ClassComponentUnderStudentSchedule h4 {
       
        font-size: 12px;
      
    }
}
  
  @media(max-width: 720px) {
   .ClassComponentUnderStudentSchedule h4 {
       
    font-size: 10px;
    
    }
}




.ClassComponentUnderStudentSchedule:hover {
    
    box-shadow: 0 15px 30px rgba(90, 88, 88, 0.4);
}




.ClassComponentUnderStudentSchedule a:hover, a:visited, a:link, a:active{
    text-decoration: none;
}



.StudentSchedule {
   

    margin: 30px 20px 400px 190px ;
    width: 300px;
   

    
   
}


.StudentSchedule h5{
   

    font-size: 15px;
    font-weight: 350; 

    
   
}

@media (max-width: 1060px) {
    .StudentSchedule {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .StudentSchedule {
       
       margin-left : 20px;
    
    }
}


.StudentSchedule h2 {
    
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;

   
}

.BookingItems {
    margin-top: 20px;
    
    display: grid;
    grid-gap: 20px;

   
}




.StudentSchedule input{
    /* this is for the text field  */
    width: 300px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 250;
    border-width: thin;
    
    
}




.InstructorSchedule {
    margin: 30px 20px 500px 190px ;
    width: 340px;
}


@media (max-width: 1060px) {
    .InstructorSchedule {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .InstructorSchedule {
       
       margin-left : 20px;
    
    }
}




.InstructorSchedule h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;

   
}

.BookingItems {
    margin-top: 20px;
    
    display: grid;
    grid-gap: 20px;

   
}





.InstructorSchedule input{
    /* this is for the text field  */
    width: 300px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 250;
    border-width: thin;
    
    
}
.ClassComponentUnderInstructorSchedule {

    width: 580px;
    height: 80px;

    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    justify-content: stretch;  


    
}

@media (max-width: 1060px) {
    .ClassComponentUnderInstructorSchedule {
       
        width : 550px;
      
    }
}
  
@media(max-width: 720px) {
   .ClassComponentUnderInstructorSchedule {
       
       width : 320px;
       height: 45px;
    
    }
}



.ssssss {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    
   
   
}


@media(max-width: 720px) {
    .ssssss {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        
       
       
    }
    
 }



.ClassComponentUnderInstructorSchedule h4 {
    
    color: black;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Cormorant', serif;

}

@media(max-width: 720px) {
    .ClassComponentUnderInstructorSchedule h4 {
        
        font-size: 11px;
     
     }
 }

 


.ClassComponentUnderInstructorSchedule h5 {
    color: black;
    font-size: 13px;
    font-weight: 400;
    
}

.ClassComponentUnderInstructorSchedule:hover {
    
    box-shadow: 0 15px 30px rgba(90, 88, 88, 0.4);
}




.ClassComponentUnderInstructorSchedule a:hover, a:visited, a:link, a:active{
    text-decoration: none;
}







.ManagePersonalInfoGroup {

    display: grid;
    margin-top : 30px;
    margin-left : 330px;
    
}


  
@media (max-width: 1060px) {
    .ManagePersonalInfoGroup {
       
        margin-left : 130px;
      
    }
}
  
@media(max-width: 720px) {
   .ManagePersonalInfoGroup {
       
       margin-left : 20px;
    
    }
}



.ManagePersonalInfoGroup h2{
   
    font-weight: 600;
    font-size: 20px;
}


.ManagePersonalInfoGroup h3{
    margin-top: 40px;
    font-size: 15px;
    font-weight: 450;
  
}



.ManagePersonalInfoGroup h5{
    
    font-size: 15px;
    font-weight: 250;
   
}


 



.ManagePersonalInfoGroup img {
    
    width: 330px;
    height: 330px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    

   
}



.editbutton {
    margin-top: 80px;
    margin-bottom: 80px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white;
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    
    

}


.editbutton:focus {
    outline:0;
}



.editbutton:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    color: black;
}
  
.ManageClassComponent {

    width: 400px;
    height: 80px;

    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    
}

@media(max-width: 580px) {
    .ManageClassComponent {
        
        width: 300px;
        height: 70px;
        
      }
  
  }


.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
   
}




.ManageClassComponent h4 {
    
    color: black;
    font-size: 15px;
    font-weight: 300;
    
}


.ManageClassComponent:hover {
    
    box-shadow: 0 15px 30px rgba(90, 88, 88, 0.4);
}




.ManageClassComponent a:hover, a:visited, a:link, a:active{
    text-decoration: none;
}
.ManageClassGroup {

 
  margin-top : 30px;
  margin-left : 90px;
  margin-bottom : 300px;
 
  
}







@media (max-width: 1060px) {
  .ManageClassGroup {
     
    margin-left : 90px;
    
  }
}


@media(max-width: 720px) {
 .ManageClassGroup {
     
     margin-left : 20px;
  
  }
}







.ManageClassGroup h2{
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 20px;
    
  
}


.ClassGroup {

  display: grid;
  grid-gap: 30px;
  
}


.CreateClassGroup {

    display: grid;
    margin-top : 30px;
    margin-bottom : 80px;
    margin-left : 330px;
    
}

@media (max-width: 1060px) {
    .CreateClassGroup {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .CreateClassGroup {
       
       margin-left : 20px;
    
    }
}




.CreateClassGroup h2 {
    font-weight: 600;
    font-size: 20px;
}

.CreateClassGroup label {
    margin-top: 40px;
    font-size: 15px;
    font-weight: 450;

}


.CreateClassGroup input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    border-width: thin;
    font-size: 15px;
    font-weight: 250;

}




textarea {
    width: 330px;
    height: 340px;

    padding: 10px 10px;
    cursor: pointer;
    overflow: hidden;
    
}
   


.CreateClassGroup input[type=submit] {
    /* save button */
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;
    

}


.CreateClassGroup input[type=submit]:focus {
    outline:0;
}



.CreateClassGroup input[type=submit]:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}

.ClassDetailGroup {

    display: grid;
    margin-top : 30px;
    margin-left : 330px;
    margin-bottom : 240px;

    
}



@media (max-width: 1060px) {
    .ClassDetailGroup {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .ClassDetailGroup {
       
       margin-left : 20px;
    
    }
}




.ClassDetailGroup h2 {
    font-weight: 600;
    font-size: 20px;
}


.ClassDetailGroup label {
    margin-top: 40px;
    font-size: 15px;
    font-weight: 450;

}




.ClassDetailGroup input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 250;
    border-width: thin;
    
    
}


   




.ClassDetailGroup input[type=submit] {
    /* save button */
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;
    

}


.ClassDetailGroup input[type=submit]:focus {
    outline:0;
}



.ClassDetailGroup input[type=submit]:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}


.ManageSessionComponent {

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 570px;
    height: 90px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    padding: 20px;
}

@media (max-width: 1060px) {
    .ManageSessionComponent {
       
        width: 470px;
      
    }
}
  
@media(max-width: 720px) {
   .ManageSessionComponent {
       width: 330px;
    
    }
}


.ManageSessionComponent h4 {
    font-size: 17px;
    color: black;
    font-family: 'Cormorant', serif;
   
    
}

@media(max-width: 720px) {


    .ManageSessionComponent h4 {
        font-size: 13px;
        
      
        
    }
 }
 


.ManageSessionComponent button {
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 130px;
    height: 40px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;
}


@media(max-width: 720px) {
    .ManageSessionComponent button {
        width: 100px;
     
     }
 }
 

.ManageSessionComponent button:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}



.ManageClassDetailGroup {

    display: grid;
    margin-top : 30px;
    margin-left : 330px;
    /* margin-right : 20px; */
    margin-bottom : 130px;
    /* width: 250px; */

}





@media (max-width: 1060px) {
    .ManageClassDetailGroup {
       
        margin-left : 130px;
        width: 300px;
    }
}
  
@media(max-width: 720px) {
   .ManageClassDetailGroup {
       
       margin-left : 10px;

    }  
}

  

.ManageClassDetailGroup h2 {
    font-weight: 600;
    font-size: 20px;
}


.ManageClassDetailGroup h3 {
    margin-top: 40px;
    font-size: 15px;
    font-weight: 450;
}


@media(max-width: 720px) {

    .ManageClassDetailGroup h3 {
 
      
        width: 300px;   
    } 
 }
 



.ManageClassDetailGroup h4 {
 
    line-height: 1.5;
    font-size: 15px;
    font-weight: 250;
}




.ManageClassDetailGroup h5 {
 
    line-height: 1.5;
    font-size: 15px;
    font-weight: 250;
    width: 500px;   
}


@media(max-width: 720px) {

    .ManageClassDetailGroup h5 {
 
      
        width: 330px;   
    } 
 }
 





.ManageClassDetailGroup label {
    margin-top: 40px;
    font-size: 15px;
    font-weight: 450;
}


.ManageClassDetailGroup input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 250;
    border-width: thin;
    
}

button.commonButton {
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;
}


button.commonButton:focus {
    outline:0;
}


button.commonButton:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}


.TimePicker {
    width: 330px;

}


.SessionGroup {
    margin-top: 30px;
    margin-bottom: 30px;
    display: grid;
    grid-gap: 30px;
}



.PersonalInfoGroup {

    display: grid;
    margin-top : 30px;
    margin-left : 330px;
    
}


  
@media (max-width: 1060px) {
    .PersonalInfoGroup {
       
        margin-left : 130px;
      
    }
}
  
@media(max-width: 720px) {
   .PersonalInfoGroup {
       
       margin-left : 20px;
    
    }
}


.avatar-uploader > .ant-upload {
    margin-top : 60px;
    width: 330px;
    height: 330px;
    margin-bottom : 20px;
  }



.PersonalInfoGroup h2 {
    
    font-weight: 600;
    font-size: 20px;
}

.PersonalInfoGroup label {


    margin-top: 40px;
    font-size: 15px;
    font-weight: 450;

}


.PersonalInfoGroup input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    border-width: thin;
    font-size: 15px;
    font-weight: 250;
    
    
}



.PersonalInfoGroup img {
    margin-top: 40px;
    
    width: 330px;
    height: 330px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    
}




.PersonalInfoGroup button {
    /* upload button */
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;
    
    

}


.PersonalInfoGroup button:focus {
    outline:0;
}



.PersonalInfoGroup button:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}



.PersonalInfoGroup input[type=submit] {
    /* save button */
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;
    

}


.PersonalInfoGroup input[type=submit]:focus {
    outline:0;
}



.PersonalInfoGroup input[type=submit]:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}





.Dashboard {
    display: grid;
    margin-top: 90px;
    margin-left: 70px;
    grid-gap: 2em;
    margin-bottom: 400px;

    
   
}


@media (max-width: 1060px) {
    .Dashboard {
        margin-top: 40px;
        margin-left: 20px;
    }
}
  
@media (max-width: 420px) {
    .Dashboard {
        margin-top: 40px;
        margin-left: 20px;
    }
}




.Dashboard h2 {
    
    color: black;
    font-size: 20px;
    font-weight: 600;
}






.DashboardGroup {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
    grid-row-gap: 3em;

    
   
}


  
@media (max-width: 960px) {
    .DashboardGroup {
      grid-template-columns: repeat(2, 1fr);
    }
}
  
@media (max-width: 820px) {
    .DashboardGroup {
      grid-template-columns: repeat(1, 1fr);
    }
}





.DashboardItem {
    display: grid;

    padding: 20px 20px;
    width: 330px;
    height: 120px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
   
    
    cursor: pointer;
    
}


@media (max-width: 1060px) {
    .DashboardItem {
        width: 300px;
        height: 110px;
    }
}
  




.DashboardItem h2 {
    
    color: black;
    font-size: 15px;
    font-weight: 420;
}



.DashboardItem h3 {
    
    color: gray;
    font-size: 14px;
    font-weight: 400;
}






.DashboardGroup a:hover {
    text-decoration: none;

  }


.BecomeAnInstructor {

    display: grid;
    margin: 30px 20px 600px 190px ;

    width: 650px;
    
}




@media (max-width: 1060px) {
    .BecomeAnInstructor {
       
        margin-left : 30px;
        width: 400px;
      
    }
}
  
  @media(max-width: 720px) {
   .BecomeAnInstructor {
       
       margin-left : 20px;
       width: 350px;
    
    }
}


.BecomeAnInstructor h6{
 font-weight: 350;
    
}












.Login {

    display: grid;

    width: 340px;
    

}





.Login h3{
   
    font-weight: 700;
    font-size: 11px;
}




.Login input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 17px;
    font-weight: 400;
    margin-top: 30px;
    border-width: thin;
    
    
}

@media(max-width: 580px) {
    .Login input{
      width: 290px;
        
      }
}


.Login input[type=submit] {
    /* save button */
    margin-top: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
  
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    

}

@media(max-width: 580px) {
    .Login input[type=submit] {
      width: 290px;
        
      }
}
  





.Login input[type=submit]:focus {
    outline:0;
}



.Login input[type=submit]:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}

.example {
    text-align: center;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
  }

.ResetPassword {

    display: grid;

    width: 340px;
    margin: 90px;
    padding-bottom: 600px;

    
   
    
}


@media (max-width: 1060px) {
    .ResetPassword {
        margin: 60px;
    }
}
  
@media (max-width: 720px) {
    .ResetPassword {
        margin: 30px;
    }
}



.ResetPassword h3{
   
    font-weight: 700;
    font-size: 11px;
}




.ResetPassword input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 17px;
    font-weight: 400;
    margin-top: 30px;
    border-width: thin;
    
    
}

@media(max-width: 580px) {
    .ResetPassword input{
      width: 290px;
        
      }
}


.ResetPassword input[type=submit] {
    /* save button */
    margin-top: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
  
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    

}

@media(max-width: 580px) {
    .ResetPassword input[type=submit] {
      width: 290px;
        
      }
}
  





.Login input[type=submit]:focus {
    outline:0;
}



.Login input[type=submit]:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}


.wholeBody {
    display: grid;
    margin: 90px;
    
    grid-gap: 1em;
    padding-bottom: 390px;

    
   
}

@media (max-width: 1060px) {
    .wholeBody {
        margin: 60px;
    }
}
  
@media (max-width: 720px) {
    .wholeBody {
        margin: 30px;
    }
}



.wholeBody a {
    color:black;
}


.wholeBody a:hover{
    color: gray;
    text-decoration: none;
}
  



.NewSignUp {

    display: grid;

    width: 340px;
    margin: 60px;
    padding-bottom: 250px;

    
   
    
}


@media(max-width: 580px) {
    .NewSignUp {
      margin: 20px;
        
      }
}





.NewSignUp h3{
   
    font-weight: 700;
    font-size: 11px;
}

.NewSignUp input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 17px;
    font-weight: 400;
    margin-top: 30px;
    border-width: thin;
    
    
}

@media(max-width: 580px) {
    .NewSignUp input{
      width: 290px;
        
      }
}




.errorMessage {
    color: black;
    font-size: 0.75em;
    display: relative;
}




.NewSignUp button {
    /* upload button */
    margin-top: 60px;
    font-size: 13px;
    font-weight: 300;
    width: 330px;
    height: 40px;
    background-color: white; /* Green */
  
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    border-width: thin;

    

}


@media(max-width: 580px) {
    .NewSignUp button {
      width: 290px;
        
      }
}
  


.NewSignUp button:focus {
    outline:0;
}



.NewSignUp button:hover {
    text-decoration: none;
    background-color:rgb(223, 221, 221 );
    border: none;
    color: black;
}





.CareerPage {

  display: grid;
  margin-top : 60px;
  margin-bottom : 500px;
  margin-left : 330px;
  grid-gap: 30px;
  width: 500px;
  


  
}


.CareerPage h2{

  font-size: 18px;
  font-weight: 600;

  
}



@media (max-width: 1060px) {
  .CareerPage {
    display: grid;
    margin-top : 60px;
    margin-bottom : 300px;
    margin-left : 130px;
    grid-gap: 30px;
    width: 500px;
  }
}

@media(max-width: 720px) {
 .CareerPage {
    display: grid;
    margin-top : 60px;
    margin-bottom : 300px;
    margin-left : 20px;
    grid-gap: 30px;
    width: 450px;
    
  }
}



@media(max-width: 480px) {
  .CareerPage {
    display: grid;
    margin-top : 60px;
    margin-bottom : 300px;
    margin-left : 15px;
    margin-right : 20px;
    grid-gap: 30px;
    width: 250px;
    
  }
}


.ContactPage {

    display: grid;
    margin-top : 60px;
    margin-bottom : 500px;
    margin-left : 330px;
    grid-gap: 30px;
    width: 500px;
  
  
    
  }
  

  .ContactPage h2{
    font-size: 18px;
    font-weight: 600;
  }
  
  
  @media (max-width: 1060px) {
    .ContactPage {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 130px;
      grid-gap: 30px;
      width: 500px;
    }
  }
  
  @media(max-width: 720px) {
   .ContactPage {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 20px;
      grid-gap: 30px;
      width: 500px;
      
    }
  }
  
  
  
  @media(max-width: 480px) {
    .ContactPage {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 15px;
      margin-right : 20px;
      grid-gap: 30px;
      width: 260px;
      
    }
  }
  


.AddAdmin {

    display: grid;
    margin: 30px 20px 400px 190px ;

    
}


@media (max-width: 1060px) {
    .AddAdmin {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .AddAdmin {
       
       margin-left : 20px;
    
    }
}





.AddAdmin h2 {
    
    font-weight: 700;
    margin-bottom: 60px;
}




.AddAdmin input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 20px;
    
    
}

.AddAdmin button {
    /* upload button */
    font-size: 18px;
    font-weight: 600;
    width: 330px;
    height: 50px;
    background-color: black; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    

}


.AddAdmin button:focus {
    outline:0;
}



.AddAdmin button:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.8); /* Green */
    color: white;
}







.FAQ {

    display: grid;
    margin-top : 60px;
    margin-bottom : 300px;
    margin-left : 330px;
    grid-gap: 30px;
    width: 500px;
  
  
    
  }
  
  
  @media (max-width: 1060px) {
    .FAQ {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 130px;
      grid-gap: 30px;
      width: 500px;
    }
  }
  
  @media(max-width: 720px) {
   .FAQ {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 20px;
      grid-gap: 30px;
      width: 500px;
      
    }
  }
  
  
  
  @media(max-width: 480px) {
    .FAQ {
      display: grid;
      margin-top : 60px;
      margin-bottom : 300px;
      margin-left : 15px;
      margin-right : 20px;
      grid-gap: 30px;
      width: 260px;
      
    }
  }
.PurchaseHistory {
    display: grid;
    grid-gap: 2em;
    margin-top : 90px;
    margin-right : 590px;
    margin-left : 190px;
    margin-bottom : 90px;
}




  
@media (max-width: 1060px) {
    .PurchaseHistory {
        margin-left : 130px;
    }
}
  
@media(max-width: 720px) {
   .PurchaseHistory {
       margin-left : 20px;
    
    }
}
.PurchaseHistoryItemComponent {
    display: grid;

    padding: 20px;

   
    width: 700px;
    height:150px;
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
   
    
    cursor: pointer;
    
}


.PurchaseHistoryItemComponent h4 {
    color: black;
    font-size: 17px;
    font-weight: 400;
}



.PurchaseHistoryItemComponent h3 {
    color: black;
    font-size: 17px;
    font-weight: 600;
}
.TransactionRecordItemComponent {
    display: grid;

    padding: 20px;

   
    width: 700px;
    height:150px;
    
    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
   
    
    cursor: pointer;
    
}


.TransactionRecordItemComponent h4 {
    color: black;
    font-size: 17px;
    font-weight: 400;
}



.TransactionRecordItemComponent h3 {
    color: black;
    font-size: 17px;
    font-weight: 600;
}
.TransactionRecord {
    display: grid;

   
    grid-gap: 2em;
    margin: 90px 20px 90px 190px ;

    
   
}

@media (max-width: 1060px) {
    .TransactionRecord {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .TransactionRecord {
       
       margin-left : 20px;
    
    }
}





.AddInstructor {
    display: grid;
   

    margin: 30px 20px 400px 190px ;

    
   
}

@media (max-width: 1060px) {
    .AddInstructor {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .AddInstructor {
       
       margin-left : 20px;
    
    }
}





.AddInstructor h2 {
    
    font-weight: 700;
    margin-bottom: 60px;
}




.AddInstructor input{
    /* this is for the text field  */
    width: 330px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 20px;
    
    
}

.AddInstructor button {
    /* upload button */
    font-size: 18px;
    font-weight: 600;
    width: 330px;
    height: 50px;
    background-color: black; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    

}


.AddInstructor button:focus {
    outline:0;
}



.AddInstructor button:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.8); /* Green */
    color: white;
}





  .ClassDetail h3{
    margin: 50px 40px 40px 0;
    font-weight: 600;
    
  
  }


  .ClassDetail h5{
    margin: 20px 40px 5px 0;
    width: 560px;
    font-size: 18px;
    font-weight: 500;
  
}



  .ClassDetail h6{
    margin: 0 40px 40px 0;
    width: 560px;
    font-weight: 300;
    line-height: 1.5;
    
 
  
}



.ClassDetail {
  margin-left : 260px;
  margin-bottom : 190px;
}


@media (max-width: 1060px) {
  .ClassDetail {
    margin-left : 160px;
  }

  .ClassDetail h6{
    width: 500px;
  }
}


@media(max-width: 720px) {
 .ClassDetail {
  margin-left : 30px;
    
  }
  .ClassDetail h6{
    width: 430px;
  }
}

@media(max-width: 480px) {
  .ClassDetail {
    margin-left : 15px;
      
    }
    .ClassDetail h6{
      width: 300px;
    }

}

















  .ClassDetail h3{
    margin: 50px 40px 40px 0;
   
    font-weight: 600;
    font-size: 20px;
    
  
  }


.ClassDetail h5{
    margin: 20px 40px 5px 0;
    width: 560px;
   
    font-size: 15px;
    font-weight: 450;
  
}

@media(max-width: 580px) {
  .ClassDetail h5 {
    width: 300px;
      
    }
}




.ClassDetail h6{
    margin: 0 40px 40px 0;
    width: 560px;
    font-size: 15px;
    font-weight: 250;
    line-height: 1.5;
    
 
  
}



.ClassDetail {
  margin-left : 260px;
  margin-bottom : 190px;
  width: 700px;
}


@media (max-width: 1060px) {
  .ClassDetail {
    margin-left : 160px;
  }

  .ClassDetail h6{
    width: 500px;
  }
}


@media(max-width: 720px) {
 .ClassDetail {
  margin-left : 30px;
    
  }
  .ClassDetail h6{
    width: 430px;
  }
}

@media(max-width: 480px) {
  .ClassDetail {
    margin-left : 15px;
      
    }
    .ClassDetail h6{
      width: 300px;
    }

}

.StudentInfoBox {

    width: 580px;
    height: 80px;

    box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    cursor: pointer;
    justify-content: stretch;  
    margin-top: 20px;


    
}

@media (max-width: 1060px) {
    .StudentInfoBox {
       
        width : 550px;
      
    }
}
  
@media(max-width: 720px) {
   .StudentInfoBox {
       
       width : 320px;
       height: 45px;
    
    }
}



.cccc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    
   
   
}


@media(max-width: 720px) {
    .cccc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        
       
       
    }
    
 }



.StudentInfoBox h4 {
    
    color: black;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Cormorant', serif;

}

@media(max-width: 720px) {
    .StudentInfoBox h4 {
        
        font-size: 11px;
     
     }
 }

 






.button {
    font-size: 18px;
    font-weight: 600;
    width: 330px;
    height: 50px;
    background-color: black; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    margin-top: 80px;
    

}


.button:focus {
    outline:0;
}



.button:hover {
    text-decoration: none;

    background-color: rgba(0, 0, 0, 0.8); /* Green */
    color: white;
}



.ConnectToStripe {
   

    margin: 90px 20px 90px 190px ;

    
   
}

@media (max-width: 1060px) {
    .ConnectToStripe {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .ConnectToStripe {
       
       margin-left : 20px;
    
    }
}

  .Checkout {

    display: grid;
    margin-top : 60px;
    margin-bottom : 580px;
    margin-left : 330px;
    grid-gap: 30px;
    width: 500px;
  
  
    
  }
  
  
  @media (max-width: 1060px) {
    .Checkout {
      display: grid;
      margin-top : 60px;
      margin-bottom : 580px;
      margin-left : 130px;
      grid-gap: 30px;
      width: 500px;
    }
  }
  
  @media(max-width: 720px) {
   .Checkout {
      display: grid;
      margin-top : 60px;
      margin-bottom : 580px;
      margin-left : 20px;
      grid-gap: 30px;
      width: 500px;
      
    }
  }
  
  
  
  @media(max-width: 480px) {
    .Checkout {
      display: grid;
      margin-top : 60px;
      margin-bottom : 580px;
      margin-left : 15px;
      margin-right : 20px;
      grid-gap: 30px;
      width: 260px;
      
    }
  }

  .AboutPage {

    display: grid;
    margin-top : 60px;
    margin-bottom : 80px;
    margin-left : 330px;
    grid-gap: 30px;
    width: 500px;
  
  
    
  }
  
  
  @media (max-width: 1060px) {
    .AboutPage {
      display: grid;
      margin-top : 60px;
      margin-bottom : 80px;
      margin-left : 130px;
      grid-gap: 30px;
      width: 500px;
    }
  }
  
  @media(max-width: 720px) {
   .AboutPage {
      display: grid;
      margin-top : 60px;
      margin-bottom : 80px;
      margin-left : 20px;
      grid-gap: 30px;
      width: 500px;
      
    }
  }
  
  
  
  @media(max-width: 480px) {
    .AboutPage {
      display: grid;
      margin-top : 60px;
      margin-bottom : 80px;
      margin-left : 15px;
      margin-right : 20px;
      grid-gap: 30px;
      width: 260px;
      
    }
  }
  

  .AboutPage {

    display: grid;
    margin-top : 60px;
    margin-bottom : 580px;
    margin-left : 330px;
    grid-gap: 30px;
    width: 500px;

  
  
    
  }
  
  
  @media (max-width: 1060px) {
    .AboutPage {
      
      margin-left : 130px;
      width: 500px;
    }
  }
  
  @media(max-width: 720px) {
   .AboutPage {
     
      margin-left : 20px;
      width: 500px;
      
    }
  }
  
  
  
  @media(max-width: 480px) {
    .AboutPage {
     
      margin-left : 15px;
      margin-right : 20px;
      width: 260px;
      
    }
  }
  
  
