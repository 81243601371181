@import url('https://fonts.googleapis.com/css2?family=Cormorant:wght@300&display=swap');

.SearchGroup  {
 
  margin-top: 30px;


}



.SearchGroup img{

  /* margin: auto 180px; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 150px;
  


}


@media (max-width: 1060px) {
  .SearchGroup img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    height: 400px;
    margin-bottom: 80px;
  }
}

@media (max-width: 720px) {
  .SearchGroup img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 300px;
    margin-bottom: 1px;
  }

}



.SearchGroup h2 {
  font-size: 35px;
  font-weight: 600;
  font-family: 'Cormorant', serif;
  text-align: center;

}



.InstructorGroup h2 {
  margin: 70px 20px;
  font-size: 35px;
  text-align: center;
  font-weight: 600;
  font-family: 'Cormorant', serif;

}


.Instructors {
    margin: 50px 40px 300px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    justify-items: center;
}


  
@media (max-width: 1060px) {
    .Instructors {
      grid-template-columns: repeat(2, 1fr);
    }
}
  
@media (max-width: 720px) {
    .Instructors {
      grid-template-columns: repeat(1, 1fr);
    }

}


