



.InstructorSchedule {
    margin: 30px 20px 500px 190px ;
    width: 340px;
}


@media (max-width: 1060px) {
    .InstructorSchedule {
       
        margin-left : 130px;
      
    }
}
  
  @media(max-width: 720px) {
   .InstructorSchedule {
       
       margin-left : 20px;
    
    }
}




.InstructorSchedule h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;

   
}

.BookingItems {
    margin-top: 20px;
    
    display: grid;
    grid-gap: 20px;

   
}





.InstructorSchedule input{
    /* this is for the text field  */
    width: 300px;
    height: 50px;
    padding: 10px 10px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: 250;
    border-width: thin;
    
    
}