.wholeBody {
    display: grid;
    margin: 90px;
    
    grid-gap: 1em;
    padding-bottom: 390px;

    
   
}

@media (max-width: 1060px) {
    .wholeBody {
        margin: 60px;
    }
}
  
@media (max-width: 720px) {
    .wholeBody {
        margin: 30px;
    }
}



.wholeBody a {
    color:black;
}


.wholeBody a:hover{
    color: gray;
    text-decoration: none;
}
  

